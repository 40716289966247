<template>
  <div class="main-content">
    <h3 class="marginBottom20">修改密码</h3>
    <avue-form
      :option="option"
      v-model="form"
      ref="form"
      @submit="handleSubmit"
    >
      <template slot="tip"
        ><div style="color: #aaaaaa">
          *忘记密码请联系管理员重置后修改
        </div></template
      >
      <template slot="tipPassWord"
        ><div style="color: #aaaaaa">
          *密码需包含大写字母、小写字母、数字和特殊字符(!@%^-_=+[{}]:,./?)
        </div></template
      >
      <template slot="menuForm">
        <el-button type="primary" @click="submit(form)">确认修改</el-button>
      </template>
    </avue-form>
  </div>
</template>

<script>
import { logout, userPassword } from "@/api";

export default {
  name: "index",
  data() {
    return {
      form: {
        oldPassword: "",
        newPassword: "",
        newPassword1: "",
      },
      option: {
        labelWidth: 120,
        submitText: "确认修改",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "原始密码",
            span: 12,
            maxlength: 30,
            showWordLimit: true,
            type: "password",
            prop: "oldPassword",
          },
          {
            label: "",
            span: 12,
            prop: "tip",
            labelWidth: 0,
          },
          {
            label: "新密码",
            span: 12,
            // row: true,
            maxlength: 20,
            showWordLimit: true,
            type: "password",
            prop: "newPassword",
            rules: [
              {
                validator: (rule, value, callback) => {
                  var pattern =
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[(!@%^-_=+\[{}\]:,.\/?)])[A-Za-z\d(!@%^-_=+\[{}\]:,.\/?)]{6,}$/;
                  if (!pattern.test(value)) {
                    callback(
                      new Error(
                        "密码需包含大写字母、小写字母、数字和特殊字符(!@%^-_=+[{}]:,./?)"
                      )
                    );
                  } else {
                    callback();
                  }
                },
                trigger: "blur",
              },
            ],
          },
          {
            label: "",
            span: 12,
            prop: "tipPassWord",
            labelWidth: 0,
          },
          {
            label: "新密码确认",
            span: 12,
            row: true,
            maxlength: 20,
            showWordLimit: true,
            type: "password",
            prop: "newPassword1",
          },
        ],
      },
    };
  },
  methods: {
    handleSubmit(form, done) {},
    submit(form) {
      let that = this;
      if (!form.oldPassword || form.oldPassword === "") {
        this.$message.error("原密码不能为空");
        return;
      }
      if (!form.newPassword || form.newPassword === "") {
        this.$message.error("新密码不能为空");
        return;
      }
      if (!form.newPassword1 || form.newPassword1 === "") {
        this.$message.error("确认密码不能为空");
        return;
      }
      if (form.newPassword !== form.newPassword1) {
        this.$message.error("确认密码错误");
        return;
      }
      if (form.newPassword.length < 6) {
        this.$message.error("密码长度不能小于6位");
        return;
      }
      userPassword(form)
        .then((res) => {
          // 获取数据成功后的其他操作
          if (res.code === 200) {
            that.$message.success(res.msg);
            setTimeout(() => {
              logout()
                .then((res) => {
                  if (res.code === 200) {
                    this.$router.push("/login");
                    this.global.Set_store();
                    this.global.SET_localStorage();
                  }
                })
                .catch((err) => {
                  this.$message.error(err.msg);
                });
            }, 1000);
          } else {
            this.$message.error(res.msg);
            // done();
          }
        })
        .catch((err) => {
          // done();
        });
    },
  },
};
</script>

<style scoped></style>
